<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Print Progress" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full h-72 overflow-auto">

            <div class="vx-row mb-3">
              <div class="vx-col w-full">
                <div class="con-vs-alert con-vs-alert-primary con-icon">
                  <div class="vs-alert con-icon">
                    <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
                    Pilih termin yang akan diajukan pada progress <span class="font-bold">{{ progressAchievedPercent | idr }}%</span>.
                  </div>
                </div>
              </div>
            </div>

            <vs-table :data="listTermin" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap w-24">#</vs-th>
                <vs-th class="whitespace-no-wrap w-24">Check</vs-th>
                <vs-th class="whitespace-no-wrap">No Termin</vs-th>
                <vs-th class="whitespace-no-wrap">Bobot Harus Tercapai</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm" :class="{ 'cursor-not-allowed': item.bobot > progressAchievedPercent }">
                  <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <vs-checkbox size="small" :disabled="item.bobot > progressAchievedPercent" v-model="item.checked"/>
                  </vs-td>
                  <vs-td class="whitespace-no-wrap">Termin {{ item.no_termin || '-' }}</vs-td>
                  <vs-td class="whitespace-no-wrap text-success" :class="{ 'text-danger': item.bobot > progressAchievedPercent }">{{ item.bobot }}%</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button @click="done" :disabled="listSelectedIdTermin.length < 1">Ok</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SpkProgressPrint',
  props: {
    isActive: { type: Boolean },
    idProgress: { required: true },
    progressAchievedPercent: { required: true }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.listTermin = _.map(_.cloneDeep(this.termins), item => {
          item.checked = false
          return item
        })
      }
    }
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    spkData () {
      return this.storeSpkShow.spkData
    },
    termins () {
      return this.spkData.pembayaran
    },
    listSelectedIdTermin () {
      return _.filter(this.listTermin, item => item.checked)
    }
  },
  data () {
    return {
      listTermin: []
    }
  },
  methods: {
    done () {
      this.emitDone()
      this.resetData()
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitDone () {
      this.$emit('done', {
        listIdTermin: _.map(this.listSelectedIdTermin, item => item.id)
      })
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
